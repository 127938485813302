var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"shadow product_card",staticStyle:{"border-radius":"8px"},on:{"click":function($event){return _vm.$emit('edit', {
      cindex: _vm.cindex,
      index: _vm.index,
    })}}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('view-image',{staticStyle:{"border-radius":"0.5rem"},attrs:{"image":_vm.image_path,"height":130,"width":130}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"font-semibold text-lg black--text m-b-4 mt-3 ml-2"},[_c('span',{staticClass:"line-clamp-1"},[_vm._v(_vm._s(_vm.name))])]),_c('div',{staticClass:"align-items-center",staticStyle:{"margin-top":"-11px"}},[_c('LightningBoltIcon',{class:_vm.status_id == 1 ? '' : 'fill-red',staticStyle:{"margin-right":"10px"},on:{"click":function($event){$event.stopPropagation();return _vm.enableDisableVehicle($event)}}}),_c('v-menu',{attrs:{"content-class":"q-menu","right":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('DotsIcon',_vm._g(_vm._b({},'DotsIcon',attrs,false),on))]}}])},[_c('v-list',[_c('v-list-item',[_vm._v(" Maintenance ")]),_c('v-divider',{staticClass:"mb-2"}),_c('v-list-item',{on:{"click":_vm.assignTransponder}},[_vm._v(" Transponder Assignment ")])],1)],1)],1)]),_c('p',{staticClass:"font-medium m-1 p-0 ml-2"},[_vm._v(" Transponder Id: "),_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(_vm.transponder_name ? _vm.transponder_name : "--"))])]),_c('p',{staticClass:"font-medium m-1 p-0 ml-2"},[_vm._v(" Status: "),_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(_vm.status_id == 1 ? "Active" : "Inactive"))])])])])],1)],1),(1 == 2)?_c('v-bottom-navigation',{attrs:{"light":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('edit', {
          cindex: _vm.cindex,
          index: _vm.index,
        })}}},[_c('span',[_vm._v("Edit")]),_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('delete', {
          cindex: _vm.cindex,
          index: _vm.index,
        })}}},[_c('span',[_vm._v("Delete")]),_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-spacer')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }