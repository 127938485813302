<template>
  <v-dialog persistent v-model="isEnableAddVehicle" width="40%">
    <v-form ref="product_form" autocomplete="off">
      <v-card>
        <v-card-text class="border-bottom mb-3">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon
                  class="text-2xl font-semibold"
                  text="Add Vehicle"
                  style="color: black"
                >
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row dense justify="left" class="mt-6">
            <v-col md="4" class="pb-0">
              <div
                style="
                  height: 207px !important;
                  width: 200px !important;
                  margin: auto;
                "
              >
                <image-uploader
                  @upload="
                    (data) => {
                      vehicle.image = data;
                    }
                  "
                  @remove="
                    () => {
                      vehicle.image = null;
                    }
                  "
                  :image_path="vehicle.image_path"
                  :height="200"
                  message-text=""
                  text="Vehicle Picture"
                ></image-uploader>
              </div>
            </v-col>
            <v-col md="8">
              <v-row>
                <v-col md="8">
                  <label for="">Model Name*</label>
                  <v-text-field
                    outlined
                    required
                    background-color="#fff"
                    v-model="vehicle.name"
                    :rules="[(v) => !!v || 'Model name is required']"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <label for="">Quantity*</label>
                  <v-text-field
                    :disabled="editFlag"
                    outlined
                    required
                    background-color="#fff"
                    v-model="vehicle.quantity"
                    :rules="[(v) => !!v || 'Quantity is required']"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <label for="">Description*</label>
                  <v-textarea
                    outlined
                    rows="4"
                    background-color="#fff"
                    required
                    v-model="vehicle.description"
                    :rules="[(v) => !!v || 'Product description is required']"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="red"
            class="ma-2"
            @click="deleteProduct(vehicle.id, vehicle.category_id)"
            v-if="vehicle && vehicle.id"
            >Delete</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="close()" class="ma-2">Close</v-btn>
          <v-btn text class="ma-2 white--text blue-color" @click="addVehicle"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>
<script>
import constants from "@/utils/constants";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";

export default {
  components: { ImageUploader, SvgIcon },
  props: {
    name: { type: String, default: "" },
    description: { type: String, default: "" },
    id: { type: Number },
    venueServiceId: { type: Number },
    image_path: { type: String },
    category_id: { type: Number, default: null },
    isEnableAddVehicle: { type: Boolean, default: false },
    editFlag: { type: Boolean, default: false },
    quantity: { type: Number, default: 0 },
    rules: { type: Array, default: () => [] },
  },
  data() {
    return {
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      vehicle: {
        name: "Vehicle Name",
        quantity: 3,
      },
      packageTypes: [
        { type: "I", name: "Individual" },
        { type: "C", name: "Couple" },
        { type: "G", name: "Group" },
      ],
      timings: constants.TIMINGS,
      taskNames: constants.TASK_NAMES,
      glCodes: constants.GL_CODES,
    };
  },
  computed: {
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
  },
  watch: {
    isEnableAddVehicle: {
      immediate: true,
      handler(val) {
        console.log(this.$props);
        if (val) {
          this.vehicle.venue_service_id = this.venueServiceId;
          this.vehicle.category_id = this.category_id;
          this.vehicle.vehicle_id = this.id;
          this.vehicle.name = this.name;
          this.vehicle.quantity = this.quantity;
          this.vehicle.description = this.description;
          this.vehicle.editFlag = this.editFlag;
          this.vehicle.image_path = this.image_path;
          console.log("Popup");
          console.log(this.vehicle);
          this.$forceUpdate();
        }
      },
    },
  },
  mounted() {
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$emit("save");
    },
    deleteProduct(productId, categoryId) {
      this.$emit("deleteProduct", productId, categoryId);
    },
    addVehicle() {
      if (!this.$refs.product_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      var formData = new FormData();
      console.log("Vehicle");
      console.log(this.vehicle);
      for (let key in this.vehicle) {
        if (this.vehicle[key] != null && key != "id") {
          formData.append(key, this.vehicle[key]);
        }
      }

      this.$http({
        method: "post",
        data: formData,
        url: `venues/mylaps/vehicles${
          this.vehicle.id ? "/" + this.vehicle.id : ""
        }`,

        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.save();
            this.showSuccess(
              "Vehicle successfully " + this.vehicle.id ? "updated" : "created."
            );
            this.vehicle = {};
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },

    confirmActions(data) {
      if (data.type == "rules") {
        this.vehicle.inventory_rules.splice(data.id, 1);
      }
      if (this.vehicle.inventory_rules.length == 0) {
        this.vehicle.inventory_rules.push({
          weekdays: [],
          start_time: "00:00:00",
          end_time: "23:59:59",
        });
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style scoped>
.v-input--switch {
  .v-input__slot {
    //flex-direction: row-reverse;
  }
}
</style>
