<template>
  <v-container fluid>
    <FacilitiesTabs
      :configurations="configurations"
      :show-add-category="true"
      :venue-service-id="venueServiceId"
      :venue-services="venueServices"
      @serviceChange="serviceChange"
      @showCategoryDialog="(value) => (vehicleCategoryDialog = value)"
      @showTransponderAssignDialog="
        (value) => (transponderAssignDialog = value)
      "
      @update:venueServiceId="(value) => (venueServiceId = value)"
    />

    <v-divider
      class="mt-4"
      style="border-color: rgba(17, 42, 70, 0.14) !important"
    />

    <v-row class="mt-8" dense>
      <v-col cols="12">
        <div class="d-flex gap-x-5">
          <v-btn
            v-for="category in vehicleCategories"
            :key="`tb_${category.category_id}`"
            :class="{
              active_category: selectedCategory === category.category_id,
            }"
            class="text-capitalize"
            outlined
            style="
              border: 1px solid rgba(17, 42, 70, 0.1);
              background-color: #ffffff;
            "
            @click="selectedCategory = category.category_id"
          >
            {{ category.name }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <template v-for="(category, ci) in vehicleCategories">
        <template v-if="selectedCategory == category.category_id">
          <v-col
            v-for="(vehicle, pi) in category.vehicles"
            :key="vehicle.id"
            lg="4"
            md="4"
            xl="3"
          >
            <vehicle-widget
              :key="pi"
              :cindex="ci"
              :index="pi"
              :service="serviceName"
              v-bind="vehicle"
              @delete="deleteVehicle"
              @assign="assignTransponder"
              @edit="editVehicle"
              @enableOrDisable="enableOrDisableVehicle"
            ></vehicle-widget>
          </v-col>
        </template>
      </template>
    </v-row>

    <v-row v-if="vehicleCategories.length" class="mt-2" dense>
      <v-col>
        <v-btn
          class="shadow-0"
          color="teal-color"
          dark
          height="48"
          @click.stop="addVehicle(selectedCategory)"
        >
          +Add Vehicle
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="1 == 2">
      <v-col>
        <v-expansion-panels
          :value="openTabs"
          accordion
          flat
          hover
          light
          multiple
          tile
        >
          <template v-for="(category, ci) in vehicleCategories">
            <v-expansion-panel v-if="category.name" :key="`c_${ci}`">
              <v-expansion-panel-header>
                <span class="month_names">{{ category.name }}</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col
                    v-for="(vehicle, pi) in category.vehicles"
                    :key="vehicle.id"
                    lg="3"
                  >
                    <vehicle-widget
                      :key="pi"
                      :cindex="ci"
                      :index="pi"
                      :service="serviceName"
                      v-bind="vehicle"
                      @delete="deleteVehicle"
                      @edit="editVehicle"
                    ></vehicle-widget>
                  </v-col>
                  <v-col md="3">
                    <v-card
                      :style="cardStyle"
                      class="pa-4 d-flex align-center justify-center"
                      height="210"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="#00b0af"
                            dark
                            elevation="0"
                            fab
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="addVehicle(ci)"
                          >
                            <v-icon>mdi-plus-circle</v-icon>
                          </v-btn>
                        </template>
                        Add Vehicle
                      </v-tooltip>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <confirm-model
      v-bind="confirmModel"
      @close="confirmModel.id = null"
      @confirm="confirmActions"
    ></confirm-model>
    <vehicle-creation
      v-if="vehicleDialog"
      :is-enable-add-vehicle="vehicleDialog"
      :venue-service-id="venueServiceId"
      v-bind="vehicleData"
      @close="vehicleClose"
      @deleteVehicle="deleteVehicle"
      @save="vehicleSave"
    />
    <vehicle-category
      v-if="vehicleCategoryDialog"
      :is-enable-vehicle-category="vehicleCategoryDialog"
      :vehicle-categories="vehicleCategories"
      :venue-service-id="venueServiceId"
      @close="(vehicleCategoryDialog = false), getVehicleAndCategory()"
      @delete="deleteCategory"
      @save="getVehicleAndCategory(), (vehicleCategoryDialog = false)"
    />

    <transponder-assign
      v-if="transponderAssignDialog"
      :is-enable-assign-transponder="transponderAssignDialog"
      :transponders="transponders"
      :vehicle-id="vehicle.id"
      :transponder-id="vehicle.transponder_id"
      @close="(transponderAssignDialog = false), getVehicleAndCategory()"
      @delete="deleteCategory"
      @save="getVehicleAndCategory(), (transponderAssignDialog = false)"
    />
  </v-container>
</template>

<script>
import VehicleWidget from "@/components/Vehicle/VehicleWidget";
import VehicleCreation from "@/components/Facility/VehicleCreation";
import VehicleCategory from "@/components/Facility/VehicleCategory";
import TransponderAssign from "@/components/Facility/TransponderAssign";
import constants from "@/utils/constants";
import FacilitiesTabs from "@/views/Facility/FacilitiesTabs.vue";

export default {
  components: {
    FacilitiesTabs,
    VehicleWidget,
    VehicleCreation,
    VehicleCategory,
    TransponderAssign,
  },

  data: () => ({
    selectedCategory: null,
    categoryId: 0,
    venueServiceId: null,
    vehicleCategories: [
      {
        title: "",
        category_id: null,
      },
    ],
    transponders: [],
    confirmModel: {
      id: null,
      title: null,
      description: null,
    },
    openTabs: [0],
    vehicleCategoryDialog: false,
    transponderAssignDialog: false,
    valid: false,
    timingConfiguration: [
      { name: null, days: [], to_time: null, from_time: null },
    ],

    timeData: constants.TIMINGS,
    vehicleDialog: false,
    vehicle: {},
    vehicleData: {},
    configurations: {},
    projectNumber: null,
    transactionType: null,
    isEnableDctErp: false,
    transponderList: [
      {
        title: "",
        category_id: null,
      },
    ],
    vehicleRouteLoaded: false,
  }),

  mounted() {
    this.vehicleRouteLoaded = true;
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then((res) => {
        if (res.length) {
          this.checkVenueService();
        }
      });
    } else {
      this.checkVenueService();
    }
    if (this.$store.getters.venueInfo) {
      if (this.$store.getters.venueInfo.enable_dct_erp) {
        this.isEnableDctErp = true;
        if (this.$store.getters.venueInfo.dct_erp_configuration) {
          this.projectNumber =
            this.$store.getters.venueInfo.dct_erp_configuration.project_no;
          this.transactionType =
            this.$store.getters.venueInfo.dct_erp_configuration.transaction_type;
        }
      } else {
        this.isEnableDctErp = false;
      }
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getSportsService;
    },
    serviceName() {
      let service = this.venueServices.find(
        (item) => item.venue_service_id == this.venueServiceId
      );
      return service ? service.name : "";
    },
  },
  methods: {
    serviceChange() {
      this.getVehicleAndCategory();
      this.initializeFacilityServices();
    },
    checkVenueService() {
      if (this.$route.params.data != null) {
        let data = JSON.parse(atob(this.$route.params.data));
        this.venueServiceId = data.venue_service_id;
      } else {
        this.venueServiceId =
          this.$store.getters.getSportsService[0].venue_service_id;
      }
      setTimeout(() => {
        this.getVehicleAndCategory();
        this.initializeFacilityServices();
      }, 10);
    },
    initializeFacilityServices() {
      if (!this.$store.getters.getConfigurationStatus(this.venueServiceId)) {
        this.$store
          .dispatch("loadConfigurationsByVenueServiceId", this.venueServiceId)
          .then((response) => {
            if (response.status == 200) {
              let data = this.$store.getters.getConfigurationByVenueServiceId(
                this.venueServiceId
              );
              this.configurations = data;
              this.$forceUpdate();
            }
          });
      } else {
        let data = this.$store.getters.getConfigurationByVenueServiceId(
          this.venueServiceId
        );
        this.configurations = data;
        this.$forceUpdate();
      }
      this.$forceUpdate();
    },
    assignTransponder(data) {
      this.transponderAssignDialog = true;
      this.vehicle.id = data.id;
      this.getAvailableTransponders();
    },
    addVehicle(selectedCategory) {
      this.vehicleData.category_id = selectedCategory;
      this.vehicleData.quantity = 1;
      this.vehicleData.editFlag = false;
      this.vehicleDialog = true;
    },

    editVehicle(data) {
      const vehicleData =
        this.vehicleCategories[data.cindex].vehicles[data.index];
      this.vehicleData.vehicle_id = vehicleData.vehicle_id;
      this.vehicleData.id = vehicleData.id;
      this.vehicleData.name = vehicleData.name;
      this.vehicleData.description = vehicleData.description;
      this.vehicleData.category_id = vehicleData.category_id;
      this.vehicleData.quantity = 1;
      this.vehicleData.image_path = vehicleData.image_path;
      this.vehicleData.editFlag = true;
      setTimeout(() => {
        this.vehicleDialog = true;
      }, 10);
    },
    enableOrDisableVehicle(data) {
      this.$http
        .put(`venues/mylaps/vehicles/${data.id}/status`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.getVehicleAndCategory();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    deleteVehicle(vehicleId) {
      // const searchvehicleById = this.vehicleCategories
      //         .flatMap(category => category.vehicles)
      //         .find(vehicle => vehicle.vehicle_id === vehicleId);
      this.confirmModel = {
        id: vehicleId,
        title: "Do you want to delete this vehicle?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete_vehicle",
      };
    },

    confirmActions(data) {
      if (data.type == "disable_vehicle") {
        this.$http
          .delete(
            `venues/vehicles/${data.id}?category_id=${this.selectedCategory}`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.vehicleDialog = false;
              this.getVehicleAndCategory();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }
    },

    getVehicleAndCategory() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/mylaps/vehicles/category/${this.venueServiceId}`)
        .then((response) => {
          this.vehicleCategories = [];
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.vehicleCategories = response.data.data;
            if (this.vehicleRouteLoaded) {
              if (this.vehicleCategories && this.vehicleCategories.length) {
                this.selectedCategory = this.vehicleCategories[0].category_id;
              }
              this.vehicleRouteLoaded = false;
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getAvailableTransponders() {
      this.showLoader();
      this.$http
        .get(
          `venues/mylaps/transponders/available?vehicle_id=${this.vehicle.id}&venue_service_id=${this.venueServiceId}`
        )
        .then((response) => {
          if (response.status == 200) {
            this.transponders = response.data.data;

            // Loop through the transponders array and check for vehicles object
            this.vehicle.transponder_id = null;
            this.transponders.forEach((transponder) => {
              if (
                transponder.vehicles &&
                transponder.vehicles.vehicle_id === this.vehicle.id
              ) {
                this.vehicle.transponder_id =
                  transponder.vehicles.transponder_id;
              }
            });
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    vehicleClose() {
      this.vehicleDialog = false;
      this.vehicleData = {};
    },
    vehicleSave() {
      this.vehicleDialog = false;
      this.vehicleData = {};
      this.getVehicleAndCategory();
    },
    deleteCategory(index) {
      this.vehicleCategories.splice(index, 1);
      if (!this.vehicleCategories.length) {
        this.vehicleCategories = [];
      }
    },
  },
};
</script>
<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}

.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.v-expansion-panel-header--active {
  color: #00b0af;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}

.month_names {
  background-color: rgb(1, 78, 97);
  padding: 6px 8px;
  border-radius: 5px;
  color: white;
}

.v-expansion-panel-header--active .month_names {
  background-color: rgb(4, 148, 184);
}

.tab_is_active {
  color: #112a46;
  font-weight: 600;

  svg {
    opacity: 1 !important;
  }
}

.q-tab-nav {
  svg {
    fill: none !important;
    stroke: black !important;
    opacity: 0.4;
    min-width: 20px;
  }
}

.active_category {
  background-color: rgba(79, 174, 175, 0.1) !important;
  color: rgba(79, 174, 175, 1) !important;
  border: 1px solid rgba(79, 174, 175, 1) !important;
}
</style>
