<template>
  <v-dialog
    persistent
    v-model="isEnableVehicleCategory"
    scrollable
    @input="close"
    width="600px"
  >
    <v-form ref="category_form" autocomplete="off">
      <v-card>
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon
                  class="text-2xl font-semibold"
                  text="Vehicle Category"
                  style="color: black"
                >
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row>
            <v-col
              md="12"
              v-for="(category, index) in vehicleCategories"
              :key="`c_${index}`"
            >
              <v-card class="pa-4 shadow-0 bordered" height="100">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      @click="deleteProductCategory(index)"
                      fab
                      x-small
                      absolute
                      top
                      right
                      dark
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  Delete
                </v-tooltip>
                <label for="">Category Name*</label>
                <v-text-field
                  v-model="category.name"
                  required
                  outlined
                  background-color="#fff"
                  :rules="[(v) => !!v || 'Category name is required']"
                  class="q-text-field shadow-0"
                  dense
                  hide-details
                ></v-text-field>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <p
                @click="addNewProductCategory"
                class="pointer text-neon text-underline font-bold text-base"
              >
                + Add Category
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()" class="ma-2">Close</v-btn>
          <v-btn
            text
            class="white--text blue-color"
            @click="saveProductCategory"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: { SvgIcon },
  props: ["isEnableVehicleCategory", "venueServiceId", "vehicleCategories"],
  data() {
    return {
      deleted_categories: [],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$emit("save");
    },
    saveProductCategory() {
      if (!this.$refs.category_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      if (!this.venueServiceId) {
        this.showError("Please add new service");
        return;
      }
      let categories = this.vehicleCategories.map((x) => {
        let obj = {};
        obj.name = x.name;
        x.id ? (obj.id = x.id) : "";
        return obj;
      });
      this.$http
        .post(`venues/mylaps/vehicles/category/${this.venueServiceId}`, {
          categories: categories,
          deleted_categories: this.deleted_categories,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$store.dispatch(
              "loadProductCategoriesByVenueServiceId",
              this.venueServiceId
            );
            this.save();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addNewProductCategory() {
      this.vehicleCategories.push({ name: null });
    },
    deleteProductCategory(index) {
      if (this.vehicleCategories[index] && this.vehicleCategories[index].id) {
        this.deleted_categories.push(this.vehicleCategories[index].id);
      }
      this.$emit("delete", index);
    },
  },
};
</script>