<template>
  <v-dialog
    persistent
    v-model="isEnableAssignTransponder"
    scrollable
    @input="close"
    width="600px"
  >
    <v-form ref="category_form" autocomplete="off">
      <v-card>
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon
                  class="text-2xl font-semibold"
                  text="Transponder Assignment"
                  style="color: black"
                >
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row>
            <v-col cols="12">
              <label class="text-dark-gray font-medium text-xs" for=""
                >Transponder List</label
              >
              <v-select
                v-model="transponderId"
                :items="transponders"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'Transponder is required']"
                background-color="#fff"
                class="q-autocomplete shadow-0"
                dense
                hide-details="auto"
                item-text="label"
                item-value="id"
                outlined
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()" class="ma-2">Close</v-btn>
          <v-btn
            text
            class="white--text blue-color"
            @click="saveVehicleTransponder"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: { SvgIcon },
  props: [
    "isEnableAssignTransponder",
    "vehicleId",
    "transponders",
    "transponderId",
  ],
  data() {
    return {
      deleted_categories: [],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$emit("save");
    },
    saveVehicleTransponder() {
      if (!this.$refs.category_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      if (!this.vehicleId) {
        this.showError("Please add new service");
        return;
      }
      console.log(this.vehicleId);
      console.log(this.transponderId);
      this.$http
        .post(`venues/mylaps/vehicles/transponders`, {
          vehicle_id: this.vehicleId,
          transponder_id: this.transponderId,
        })
        .then((response) => {
          if (response.status == 200) {
            this.save();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>