<template>
  <v-card
    style="border-radius: 8px"
    class="shadow product_card"
    @click="
      $emit('edit', {
        cindex: cindex,
        index: index,
      })
    "
  >
    <v-card-text>
      <!-- <v-row>
        <v-col cols="11"> </v-col>
        <v-col cols="1">
          <LightningBoltIcon :class="is_public ? '' : 'fill-red'" />
        </v-col>
      </v-row> -->

      <v-row dense>
        <v-col cols="4">
          <view-image
            :image="image_path"
            style="border-radius: 0.5rem"
            :height="130"
            :width="130"
          ></view-image>
        </v-col>

        <v-col cols="8" class="">
          <div>
            <div class="d-flex justify-space-between">
              <p class="font-semibold text-lg black--text m-b-4 mt-3 ml-2">
                <span class="line-clamp-1">{{ name }}</span>
              </p>

              <div class="align-items-center" style="margin-top: -11px">
                <LightningBoltIcon
                  :class="status_id == 1 ? '' : 'fill-red'"
                  style="margin-right: 10px"
                  @click.stop="enableDisableVehicle"
                />
                <v-menu content-class="q-menu" right top>
                  <template v-slot:activator="{ on, attrs }">
                    <DotsIcon v-bind="attrs" v-on="on" />
                  </template>
                  <v-list>
                    <v-list-item> Maintenance </v-list-item>
                    <v-divider class="mb-2" />
                    <v-list-item @click="assignTransponder">
                      Transponder Assignment
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>

            <p class="font-medium m-1 p-0 ml-2">
              Transponder Id:&nbsp;
              <span class="text-elepsis">{{
                transponder_name ? transponder_name : "--"
              }}</span>
            </p>
            <p class="font-medium m-1 p-0 ml-2">
              Status:
              <span class="text-elepsis">{{
                status_id == 1 ? "Active" : "Inactive"
              }}</span>
            </p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-bottom-navigation light v-if="1 == 2">
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="
          $emit('edit', {
            cindex: cindex,
            index: index,
          })
        "
      >
        <span>Edit</span>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        text
        @click="
          $emit('delete', {
            cindex: cindex,
            index: index,
          })
        "
      >
        <span>Delete</span>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn text>
        <span>View</span>
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <v-spacer></v-spacer> -->
    </v-bottom-navigation>
  </v-card>
</template>

<script>
import DotsIcon from "@/assets/images/misc/h-options.svg";
import LightningBoltIcon from "@/assets/images/facilities/lightning-bolt.svg";
//import DotsIcon from "@/assets/images/misc/h-options.svg";
// TODO:
export default {
  components: { LightningBoltIcon, DotsIcon },
  props: {
    name: { type: String, default: "Vehicle name" },
    status_id: { type: Number },
    transponder_name: { type: String, default: "" },
    id: { type: Number },
    index: { type: Number },
    cindex: { type: Number },
    image_path: { type: String, default: "" },
    service: { type: String, default: "NA" },
  },

  methods: {
    assignTransponder() {
      this.$emit("assign", {
        id: this.id,
      });
    },
    enableDisableVehicle() {
      this.$emit("enableOrDisable", {
        id: this.id,
      });
    },
  },
};
</script>
<style scoped>
.product_details {
  font-size: 30px;
}
.product_card {
  border: 1px solid rgba(17, 42, 70, 0) !important;
  cursor: pointer;
}
.fill-red {
  fill: red !important;
}
.text-elepsis {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  max-width: 85%;
  overflow: hidden;
}
.product_card:hover {
  border: 1px solid rgba(17, 42, 70, 1) !important;
}
</style>